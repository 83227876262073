import React, { Component } from 'react';
import '../styles/style.css';

class Footer extends Component {

	render() {
		return(
    	  <div className="component-Footer">
		    <footer>
		    
		      <span className="footer-content">
	      		&#169; Cynthia Lee 2020
		      </span>
		  	  
		    </footer>
		   </div>
		)
	}
}

export default Footer;